/* Reset and Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f4f4f4;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Button Styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

/* Home Page Styles */
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

.homepage h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #333;
}

.homepage p {
  font-size: 20px;
  margin-bottom: 40px;
  color: #666;
}

/* Login Modal Styles */
.login-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 320px;
}

.login-modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.login-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-modal input:focus {
  border-color: #007bff;
  outline: none;
}

.login-modal button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-modal button:hover {
  background-color: #0056b3;
}

.login-modal .alternative-login {
  text-align: center;
  margin-top: 20px;
  color: #666;
  font-size: 14px;
}

.login-modal .alternative-login a {
  color: #007bff;
}

/* General Styles for Forms and Inputs */
input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

label {
  margin-bottom: 5px;
  color: #333;
}

textarea {
  resize: vertical;
}

/* Footer Styles */
footer {
  text-align: center;
  padding: 20px;
  background-color: #007bff;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer p {
  margin: 0;
}
/* src/index.css or your main CSS file */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.modal-content label {
  margin-bottom: 10px;
  font-weight: bold;
}

.modal-content input,
.modal-content select,
.modal-content textarea {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}
.modal-content {
  background-color: white;
  padding: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: white;
}

.file-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
}

.full-screen-image {
  max-width: 100%;
  max-height: 100%;
}

.full-screen-pdf {
  width: 100%;
  height: 100%;
  border: none;
}

.close-button {
  background-color: #ff5f5f;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
}

.close-button:hover {
  background-color: #d9534f;
}
/* Reset and Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f4f4f4;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Button Styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

/* Home Page Styles */
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
}

.homepage h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #333;
}

.homepage p {
  font-size: 20px;
  margin-bottom: 40px;
  color: #666;
}

/* Login Form Styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  width: 320px;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form .alternative-login {
  text-align: center;
  margin-top: 20px;
  color: #666;
  font-size: 14px;
}

.login-form .alternative-login a {
  color: #007bff;
}

/* Footer Styles */
footer {
  text-align: center;
  padding: 20px;
  background-color: #007bff;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer p {
  margin: 0;
}
/* Admin View Styles */
.admin-view {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.admin-view h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.admin-view .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-view .actions input[type="file"],
.admin-view .actions input[type="text"] {
  width: calc(50% - 10px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.admin-view .actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.admin-view .actions button:hover {
  background-color: #0056b3;
}

.admin-view .folder-structure {
  margin-top: 20px;
}

.folder-structure table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.folder-structure table th,
.folder-structure table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.folder-structure table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.folder-structure table tr:hover {
  background-color: #f1f1f1;
}

.folder-structure button {
  padding: 5px 10px;
  background-color: #ff5f5f;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}

.folder-structure button:hover {
  background-color: #d9534f;
}

.folder-structure .view-button {
  background-color: #007bff;
}

.folder-structure .view-button:hover {
  background-color: #0056b3;
}

/* Folder and File View Styles */
.folder-structure ul {
  list-style-type: none;
  padding: 0;
}

.folder-structure li {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder-structure li:hover {
  background-color: #f1f1f1;
}

.folder-structure li .actions {
  display: flex;
  gap: 10px;
}

.folder-structure li .actions button {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
}

.folder-structure li .actions .view-button {
  background-color: #007bff;
}

.folder-structure li .actions .delete-button {
  background-color: #ff5f5f;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-view .actions {
    flex-direction: column;
    align-items: stretch;
  }

  .admin-view .actions input[type="file"],
  .admin-view .actions input[type="text"],
  .admin-view .actions button {
    width: 100%;
    margin-bottom: 10px;
  }

  .admin-view .actions button {
    margin-left: 0;
  }
}
/* Add this to your main CSS file if necessary */
.thumbnail-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  background-color: #fafafa;
}

.thumbnail-container img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.thumbnail-container p {
  font-size: 14px;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
